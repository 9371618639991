<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card pt-4" style="padding-left: 5%; padding-right: 5%">
        <div class="row">
          <h2 class="col-lg-11">{{ section.titre }}</h2>
          <div class="col-lg-1">
            <p class="card-title-desc">
              <b-dropdown
                class="card-drop"
                variant="white"
                menu-class="dropdown-menu-end"
                right
                toggle-class="p-0"
              >
                <template v-slot:button-content>
                  <i class="mdi mdi-dots-horizontal font-size-18"></i>
                </template>
                <b-dropdown-item @click="showDetailModal">{{
                  $t("pages.sections.actions.details")
                }}</b-dropdown-item>
                <b-dropdown-item @click="showUpdateModal">Ajouter un programme</b-dropdown-item>
                <b-dropdown-item @click="showUpdateModal1">Retirer un programme</b-dropdown-item>
                <b-dropdown-item @click="confirm(section.id)">{{
                  $t("pages.sections.actions.supprimer")
                }}</b-dropdown-item>
              </b-dropdown>
            </p>
          </div>
        </div>

        <div class="display">
          <programmeCardVue
            v-for="programme in section.program.slice().reverse()"
            :key="programme.id"
            :programme="programme"
          />
        </div>
      </div>
    </div>
  </div>

  <b-modal
    v-model="updateModal"
    id="modal-xl"
    size="xl"
    :title="$t('pages.sections.formulaire.updateTitle')"
    title-class="font-18"
    hide-footer
  >
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-lg-12">
            <label class="col-form-label">{{
              $t("pages.sections.formulaire.nom.label")
            }}</label>
            <div class="col-md-11">
              <input
                id="projectname"
                name="projectname"
                type="text"
                class="form-control"
                v-model="section.titre"
                :placeholder="$t('pages.sections.formulaire.nom.placeholder')"
              />
            </div>
          </div>
        </div>
      </form>
      <div class="col-lg-1">
        <br /><br />
        <button @click="addProgrammeInSection" class="btn c2play-primary">
          {{ $t("updateButton") }}
        </button>
      </div>
<!-- 
      <br />
      <div class="row">
        <div
          class="col-lg-3"
          v-for="programme in program.slice().reverse()"
          :key="programme.id"
        >
          <b-form-checkbox
            v-model="idVideosUpdateSection"
            name="checkbox-1"
            :value="programme.id"
            unchecked-value="video.id"
          >
            <div>
              {{ programme.nom }}
            </div>
          </b-form-checkbox>
        </div>
      </div>
      <br />
      <div style="width: 100%; border: 1px solid #007bff"></div>-->
      <br /> 

      <div class="row">
        <div
          class="col-lg-3"
          v-for="allProgram in allProgrammes.slice().reverse()"
          :key="allProgram.id"
        >
          <b-form-checkbox
            v-model="idVideosUpdateSection"
            name="checkbox-1"
            :value="allProgram.id"
            unchecked-value="video.id"
          >
            <div>
              {{ allProgram.nom }}
            </div>
          </b-form-checkbox>
        </div>
      </div>
    </div>
  </b-modal>

  <b-modal
    v-model="updateModal1"
    id="modal-xl"
    size="xl"
    :title="$t('pages.sections.formulaire.updateTitle')"
    title-class="font-18"
    hide-footer
  >
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-lg-12">
            <label class="col-form-label">{{
              $t("pages.sections.formulaire.nom.label")
            }}</label>
            <div class="col-md-11">
              <input
                id="projectname"
                name="projectname"
                type="text"
                class="form-control"
                v-model="section.titre"
                :placeholder="$t('pages.sections.formulaire.nom.placeholder')"
              />
            </div>
          </div>
        </div>
      </form>
      <div class="col-lg-1">
        <br /><br />
        <button @click="removeProgrammeInSection" class="btn c2play-primary">
          {{ $t("updateButton") }}
        </button>
      </div>

      <br />
      <div class="row">
        <div
          class="col-lg-3"
          v-for="programme in program.slice().reverse()"
          :key="programme.id"
        >
          <b-form-checkbox
            v-model="idVideosUpdateSection"
            name="checkbox-1"
            :value="programme.id"
            unchecked-value="video.id"
          >
            <div>
              {{ programme.nom }}
            </div>
          </b-form-checkbox>
        </div>
      </div>
      <!-- <br />
      <div style="width: 100%; border: 1px solid #007bff"></div>

      <br /> 

      <div class="row">
        <div
          class="col-lg-3"
          v-for="allProgram in allProgrammes.slice().reverse()"
          :key="allProgram.id"
        >
          <b-form-checkbox
            v-model="idVideosUpdateSection"
            name="checkbox-1"
            :value="allProgram.id"
            unchecked-value="video.id"
          >
            <div>
              {{ allProgram.nom }}
            </div>
          </b-form-checkbox>
        </div>
      </div> -->
    </div>
  </b-modal>


  <b-modal
    v-model="detailModal"
    id="modal-xl"
    size="xl"
    title="Détail de la section"
    title-class="font-18"
    hide-footer
  >
    <div class="card-body">
      <h1>{{ section.titre }}</h1>
      <div class="row">
        <programmeCardVue
          v-for="programme in section.program.slice().reverse()"
          :key="programme.id"
          :programme="programme"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import programmeCardVue from "../programmeCard.vue";

import Swal from "sweetalert2";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";

export default {
  components: {
    programmeCardVue,
  },
  data() {
    return {
      section: {
        id: "",
        titre: "",
        program: [],
      },
      programmes: [],
      updateModal: false,
      updateModal1: false,
      detailModal: false,
      idVideosUpdateSection:[],
    };
  },
  props: {
    id: {},
    titre: {},
    program: [],
    allProgrammes: [],
  },
  mounted() {
    this.section.id = this.id;
    this.section.titre = this.titre;
    this.section.program = this.program;
    this.programmes = this.allProgrammes;
  },
  methods: {
    showUpdateModal() {
      this.updateModal = true;
    },
    showUpdateModal1() {
      this.updateModal1 = true;
    },
    showDetailModal() {
      this.detailModal = true;
    },

    addProgrammeInSection() {
      
      Api.putFormData(
        `/streamvod/rest/sectionprogramme/add-programme/section?programmeId=${this.idVideosUpdateSection}&sectionId=${this.id}`
      )
        .then((response) => {
          this.process = false;
          Swal.fire("Succes!", "Programme ajouté avec succès", "success");
          console.log(response);
          location.reload()
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    removeProgrammeInSection() {
      
      Api.putFormData(
        `/streamvod/rest/sectionprogramme/remove-programme/section?programmeId=${this.idVideosUpdateSection}&sectionId=${this.id}`
      )
        .then((response) => {
          this.process = false;
          Swal.fire("Succes!", "Programme retiré avec succès", "success");
          console.log(response);
          location.reload()
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
};
</script>

<style></style>
