<script>
var moment = require("moment");
moment.locale("fr");
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Swal from "sweetalert2";
// import loaderProcess from "../../../components/widgets/loaderProcess.vue";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import section_block from "../../../components/widgets/card/section_block.vue";
/**
 * Projects-list component
 */
export default {
  page: {
    title: "Projects List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // loaderProcess,
    section_block,
  },

  data() {
    return {
      process: false,
      updateModal: false,
      idPrgram: [],
      oneSection: [],
      titre: "",
      titreSection: "",
      sectionUpdate: [],
      idVideosUpdateSection: [],
      idSec: "",

      programme: {
        titre: "",
        description: "",
        duree: "",
      },
      sections: [],
      modalOk: false,
      categ: {
        titre: "",
        description: "",
      },
      categories: [],
      channels: [],
      moment: moment,
      contenu: "video",
      videos: [],
      programmes: [],
      title: "Liste des sections des Programmes",
      items: [
        {
          text: "Gestion des sections",
          // href: "/projects/sections",
        },
        {
          text: "Sections",
          active: true,
        },
      ],
    };
  },
  methods: {
    showModal() {
      this.modalOk = true;
    },

    confirm(sectionId) {
      Swal.fire({
        title: "Etes vous sùre?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Supprimer",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteProgramme(sectionId);
          Swal.fire("Deleted!", "Event has been deleted.", "success");
          location.reload();
        }
      });
    },
    deleteProgramme(sectionId) {
      Api.delete("/streamvod/rest/section/delete/" + sectionId)
        .then((response) => {
          this.channels = response.data.content;
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },
    getSection() {
      Api.get("/streamvod/rest/sectionprogramme/all")
        .then((response) => {
          this.sections = response.data.content;
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    createSection() {
      this.process = true;
      this.modalOk = false;

      Api.postFormData(
        `/streamvod/rest/sectionprogramme/create-section/checking`,
        {
          titre: this.titre,
          programmes_id: this.idPrgram,
        }
      )
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Section Créée", "success");
          // location.reload()
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    getProgrammes() {
      Api.get("/streamvod/rest/programme/all")
        .then((response) => {
          this.programmes = response.data.content;
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },
    getVideos() {
      this.process = true;

      Api.get("/streamvod/rest/videos/all")
        .then((res) => {
          this.process = false;
          this.videos = res.data.content;
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    selectOneSection(idSection) {
      this.process = true;

      Api.get("/streamvod/rest/section/" + idSection)
        .then((res) => {
          this.updateModal = true;
          this.process = false;

          this.oneSection = res.data.content;
          this.titreSection = this.oneSection.titre;
          this.idSec = this.oneSection.id;
          this.sectionUpdate.push(...this.oneSection.videos);
          this.sectionUpdate.forEach((items) => {
            this.idVideosUpdateSection.push(items.id);
          });
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },
    updateSection() {
      this.process = true;
      this.modalOk = false;

      Api.putFormData(
        "/streamvod/rest/section/update/" +
          this.idSec +
          "?videos_id=" +
          this.idVideosUpdateSection,
        {
          titre: this.titreSection,
        }
      )
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Section Modifiée", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    this.getSection();
    this.getProgrammes();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-end">
      <div class="col-lg-10" style="margin-top: 10px">
        <button
          type="submit"
          class="btn c2play-primary"
          style="position: relative; left: 90%"
          @click="showModal"
        >
          {{ $t("addButton") }}
        </button>
      </div>
    </div>
    <br />
    <div v-for="section in sections" :key="section.id">
      <section_block
      :id="section.id"
      :titre="section.titre"
      :program="section.programmes"
      :allProgrammes="programmes"
      ></section_block>
    </div>

    <!-- end row -->

    <!-- <div class="row">
      <div class="col-12">
        <div class="text-center my-3">
          <a href="javascript:void(0);" class="text-success">
            <i class="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
            Load more
          </a>
        </div>
      </div>
    </div> -->

    <b-modal
      v-model="modalOk"
      id="modal-xl"
      size="xl"
      :title="$t('pages.sections.formulaire.title')"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-lg-12">
              <label class="col-form-label">{{
                $t("pages.sections.formulaire.nom.label")
              }}</label>
              <div class="col-md-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  v-model="titre"
                  :placeholder="$t('pages.sections.formulaire.nom.placeholder')"
                />
              </div>
            </div>
          </div>
        </form>
        <div class="col-lg-1">
          <br /><br />
          <button @click="createSection" class="btn c2play-primary">
            {{ $t("addButton") }}
          </button>
        </div>

        <br />
        <div class="row">
          <div
            class="col-lg-3"
            v-for="program in programmes.slice().reverse()"
            :key="program.id"
          >
            <b-form-checkbox
              v-model="idPrgram"
              name="checkbox-1"
              :value="program.id"
              unchecked-value="video.id"
            >
              <div>
                {{ program.nom }}
              </div>
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- end row -->
    <!-- <loaderProcess v-if="process == true"></loaderProcess> -->
  </Layout>
</template>

<style>
</style>